//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
//App
import { ReviewSubmitModalComponent } from './review-submit-modal/review-submit-modal.component';
import { SafetyProcess, SafetyProcessesService, SafetyProcessList } from './services/safety-processes.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { SubmitBatchService } from '../shared/batch-assignment/submit-batch.service';
import { LegacyBatch as Legacy_Batch } from '../shared/batch-assignment/batch';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { DriverTrainingCourse } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';
import { MatDialog } from '@angular/material/dialog';

// DHP Next Gen
import { FeatureFlagService } from '../../services/launchdarkly-service/feature-flag.service';
import { BatchUtilities } from '../../services/fleet-command-services';
import {
  Batch
} from '../models';
import { EmployeeDriverInfo } from '../safety-employee-driver-form/safety-employee-driver-form';

@Component({
  selector: 'app-orders-and-assignments',
  templateUrl: './orders-and-assignments.component.html',
  styleUrls: ['./orders-and-assignments.component.scss']
})
export class OrdersAndAssignmentsComponent implements OnInit {

  @ViewChild("stepper") stepper: MatStepper;

  private _safetyProcesses: Map<SafetyProcessList, SafetyProcess>;
  private _safetyClientSelected: string;
  private readonly _subscriptions: Array<Subscription>;
  private _processesTabApplicableProcesses: string[] = Object.values(SafetyProcessList)
    .filter(process => process !== SafetyProcessList.Training && SafetyProcessList.Telematics);
  private _trainingTabApplicableProcesses: string[] = [SafetyProcessList.Training, SafetyProcessList.Telematics];
  public processesTabProceses = new Map<SafetyProcessList, SafetyProcess>();
  public trainingTabProcesses = new Map<SafetyProcessList, SafetyProcess>();
  public processesTabHeaders: TabHeader;
  public trainingTabHeaders: TabHeader;
  public trainingAssignmentsForm: UntypedFormGroup = new UntypedFormGroup({});
  public safetyProcessesForm: UntypedFormGroup = new UntypedFormGroup({});
  public nbrDrivers: number;
  public multipleServicesFormGroupName = 'multipleServicesForm';
  public trainingFormGroupName = 'trainingLessonsForm';
  public isSubmitted = false;
  selectedIndex = 0;

  // DHP Next Gen
  canUseNewBatchProcess: boolean;
  batchRequest: Array<Batch> = [];
  selectedDriverGroups = {
    employee: [],
    nonEmployee: []
  };

  constructor(
    private readonly lineOfBusinessService: LineOfBusinessService,
    private readonly safetyProcessService: SafetyProcessesService,
    private readonly submitBatchService: SubmitBatchService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly alertService: AlertService,
    private readonly routerService: Router,
    private readonly errorService: ErrorModalService,
    private readonly dialog: MatDialog,
    private readonly batchUtilities: BatchUtilities,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this._subscriptions = new Array<Subscription>();
  }


  ngOnInit(): void {
    // set line of business to safety if not already initialized to Safety
    const lob = this.lineOfBusinessService.getLineOfBusinessValue();

    if (lob !== 2) {
      this.lineOfBusinessService.setLineOfBusiness(2);
    }
    this._subscriptions.push(this.safetyProcessService.safetyProcesses$.subscribe((data) => this.safetyProcessesServiceHandler(data)));
  }

  launchSubmitBatchModal() {
    const selectedDriversForm = this.safetyProcessesForm.controls['selectedDrivers'] as UntypedFormGroup;
    this.nbrDrivers = selectedDriversForm.controls['includedDrivers']?.value?.length || 0;
    const dialogRef = this.dialog.open(ReviewSubmitModalComponent, {
      data: { prompt: `You have ${this.nbrDrivers} drivers selected. Are you sure you want to submit this batch?` },
      width: '50em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.submitBatch();
      }
    })
  }

  setSelectedDriverGroups(drivers: { employee: number[], nonEmployee: number[] }): void {
    // DHP Next Gen
    this.selectedDriverGroups.employee = drivers.employee;
    this.selectedDriverGroups.nonEmployee = drivers.nonEmployee;
  }

  private buildTabHeader(tabNumber: number, headerLabel: string): string {
    // mdbootstrap tabs require the header to be defined as a string to be passed into the component as an argument.
    let output = '';
    output += '<div class="headingNumber"><div class="headingNumberAlign">' + tabNumber.toString() + '</div></div>';
    output += '<div class="heading">' + headerLabel + '</div>';
    return output;
  }

  private createBatchServicesDriverInfo(form: FormGroup): EmployeeDriverInfo {
    // DHP Next Gen

    const driverInfo = new EmployeeDriverInfo();

    // Certificate of Insurance
    driverInfo.requestCOI = form.controls[SafetyProcessList.AutoCoverage].value;

    // Certificate of Violation
    driverInfo.requestCOV = form.controls[SafetyProcessList.CertificateOfViolation].value

    // Driver License Upload
    driverInfo.driverLicenseUpload = form.controls[SafetyProcessList.LicenseUpload].value;
    driverInfo.driverLicenseUploadDateDue = form.controls[SafetyProcessList.LicenseUpload + 'dd'].value;

    // Driver License Verification
    driverInfo.confirmLicenseInfo = form.controls[SafetyProcessList.LicenseVerifcation].value
    driverInfo.confirmLicenseInfoDateDue = form.controls[SafetyProcessList.LicenseVerifcation + 'dd'].value

    // Driver Qualification
    driverInfo.dqRequestCertOfRoadTest = form.controls[SafetyProcessList.DqCertificateOfRoadTest].value;
    driverInfo.dqRequestCOV = form.controls[SafetyProcessList.DqCertificateOfViolation].value;
    driverInfo.dqRequestClearingHouseSignoff = form.controls[SafetyProcessList.DqClearingHouse].value;
    driverInfo.dqRequestApplication = form.controls[SafetyProcessList.DqDriverApplication].value;
    driverInfo.dqRequestLicenseUpload = form.controls[SafetyProcessList.DqLicenseUpload].value;
    driverInfo.dqRequestDriverLog = form.controls[SafetyProcessList.DqDriverLog].value;
    driverInfo.dqRequestMedCard = form.controls[SafetyProcessList.DqMedCard].value;
    driverInfo.dqRequestRecordOfRoadTest = form.controls[SafetyProcessList.DqRecordOfRoadTest].value;

    // MVR Monitoring
    driverInfo.enrollInMonitoring = form.controls[SafetyProcessList.Monitoring].value;

    // MVR Order - Employee & Non-employee
    driverInfo.requestMVR = form.controls[SafetyProcessList.Mvr].value;

    // Policy Signoff
    driverInfo.orderPolicyTask = form.controls[SafetyProcessList.PolicySignOff].value;
    driverInfo.policyTaskDueDate = form.controls[SafetyProcessList.PolicySignOff + 'dd'].value;

    return driverInfo;
  }

  private createEmployeeBatch(batchBase: Batch, driverInfo: EmployeeDriverInfo): void {
    // DHP Next Gen

    const employeeDrivers = this.selectedDriverGroups.employee as number[];
    if (employeeDrivers.length > 0) {

      const primaryBatch = {
        ...batchBase,
        driverIds: employeeDrivers,
        services: []
      } as Batch;

      // Certificate Of Insurance
      if (driverInfo.requestCOI) {
        primaryBatch.services.push(this.batchUtilities.createCertificateOfInsuranceService());
      }

      // Certificate Of Violation
      /**
       * Note: The second condition evaluates LicenseVerification, however COV is
       * disabled unless you select Order MVR. Leaving the condition in for now.
       * */
      if ((driverInfo.requestMVR || driverInfo.confirmLicenseInfo) && driverInfo.requestCOV) {
        primaryBatch.services.push(this.batchUtilities.createCertificateOfViolationService());
      }

      // Driver license Upload
      if (driverInfo.driverLicenseUpload) {
        primaryBatch.services.push(this.batchUtilities.createDriverLicenseUploadService(driverInfo));
      }

      // Driver License Verification
      if (driverInfo.confirmLicenseInfo) {
        primaryBatch.services.push(this.batchUtilities.createDriverLicenseVerificationService(driverInfo));
      }

      // Driver Qualification
      if (driverInfo.dqRequired) {
        primaryBatch.services.push(this.batchUtilities.createDriverQualificationService(driverInfo));
      }

      // MVR Monitoring
      if (driverInfo.enrollInMonitoring) {
        primaryBatch.services.push(this.batchUtilities.createMvrMonitoringService());
      }

      // MVR Order
      if (driverInfo.requestMVR) {
        primaryBatch.services.push(this.batchUtilities.createMvrOrderService());
      }

      // Policy Signoff
      if (driverInfo.orderPolicyTask) {
        primaryBatch.services.push(this.batchUtilities.createPolicySignoffService(driverInfo));
      }

      this.batchRequest.push(primaryBatch);
    }
  }

  private createNonEmployeeBatch(batchBase: Batch, driverInfo: EmployeeDriverInfo): void {
    // DHP Next Gen

    const nonEmployeeDrivers = this.selectedDriverGroups.nonEmployee as number[];

    if (nonEmployeeDrivers.length > 0) {
      const secondaryBatch = {
        ...batchBase,
        driverIds: nonEmployeeDrivers,
        services: []
      } as Batch;

      // Driver license Upload
      if (driverInfo.driverLicenseUpload) {
        secondaryBatch.services.push(this.batchUtilities.createDriverLicenseUploadService(driverInfo));
      }

      // MVR Order
      if (driverInfo.requestMVR) {
        secondaryBatch.services.push(this.batchUtilities.createMvrOrderNonEmployeeService());
      }

      this.batchRequest.push(secondaryBatch);
    }
  }

  private createTrainingBatch(batchBase: Batch, driverInfo: EmployeeDriverInfo): void {
    // DHP Next Gen

    const trainingDriversForm = this.trainingAssignmentsForm.controls['selectedDrivers'] as FormGroup;
    const trainingBatch = {
      ...batchBase,
      driverIds: trainingDriversForm.controls['includedDrivers'].value as Array<number>,
      services: []
    } as Batch;

    trainingBatch.services.push(this.batchUtilities.createTrainingService(
      this.batchUtilities.getSelectedTrainingCourseData(this.getSelectedDriverTrainingCourses()),
      driverInfo
    ));

    this.batchRequest.push(trainingBatch);
  }

  private getSelectedDriverTrainingCourses(): DriverTrainingCourse[] {
    // DHP Next Gen

    const selectedTrainingLessonsForm = this.trainingAssignmentsForm.controls[this.trainingFormGroupName] as FormGroup;
    const trainingLessonsForm = selectedTrainingLessonsForm.controls['trainingSelections'] as FormGroup;
    const trainingLessonsFormArray = trainingLessonsForm.controls['selectedLessons'] as FormArray;

    return trainingLessonsFormArray.controls
      .filter((f: FormGroup) => f.controls['selected'].value)
      .map((f: FormGroup) => {
        return f.controls['lesson'].value as DriverTrainingCourse;
      });
  }

  private precheckTrainingOptions(): boolean {
    // DHP Next Gen

    const trainingForm = this.trainingAssignmentsForm.controls['trainingLessonsForm'] as FormArray;
    const trainingSelections = trainingForm.controls['servicesSelections'] as FormGroup;

    return (trainingSelections.controls['training'].value || this.getSelectedDriverTrainingCourses().length > 0) ? true : false;
  }

  private promptUserErrorAlert(displayMessage: string): void {
    this.loadingSpinnerService.hide();
    this.alertService.showErrorAlert(
      displayMessage,
      'end',
      'top',
      10000
    );
  }

  private safetyProcessesServiceHandler(data: Map<SafetyProcessList, SafetyProcess>): void {

    this._safetyProcesses = data;
    this._safetyProcesses.forEach((value, key) => {
      if (this._processesTabApplicableProcesses.includes(key)) {
        this.processesTabProceses.set(key, value);
      } else if (
        this._trainingTabApplicableProcesses.includes(key)) {
        this.trainingTabProcesses.set(key, value);
      }

    });
    this._safetyClientSelected = this.safetyProcessService.safetyClientSelected;
    let currentTab = 1;
    if (this.processesTabProceses.size > 0) {
      this.processesTabHeaders = {
        criteriaHeaderHtml: this.buildTabHeader(currentTab, 'Select Criteria for MVRs'),
        driverSelectionHeaderHtml: this.buildTabHeader(currentTab + 1, 'Select Drivers for MVRs')
      };
      currentTab += 2;
    }
    if (this.trainingTabProcesses.size > 0) {
      this.trainingTabHeaders = {
        criteriaHeaderHtml: this.buildTabHeader(currentTab, 'Select Criteria for Training'),
        driverSelectionHeaderHtml: this.buildTabHeader(currentTab + 1, 'Select Drivers for Training')
      };
      currentTab += 2;
    }

    this.subscribeToLaunchDarkly();
  }

  private submitBatch(): void {
    if (this.canUseNewBatchProcess) {
      this.processBatchRequest();
    } else {
      this.processLegacyRequest();
    }
  }

  private subscribeToLaunchDarkly(): void {
    const featureFlag = 'orders-and-assignments-client-list';
    this.featureFlagService
    .getFlag(featureFlag, false)
    .subscribe((variation) => {
      this.canUseNewBatchProcess = this.featureFlagService.evaluateClientVariation(variation, this._safetyClientSelected, featureFlag);
    });
  }

  private processBatchRequest(): void {
    // DHP Next Gen

    this.loadingSpinnerService.show();
    this.batchRequest = [];

    const batchBase: Batch = {
      applicationSource: this.batchUtilities.batchSource,
      clientCode: this._safetyClientSelected
    } as Batch;

    if (this.safetyProcessesForm.valid) {
      // Setup driver form data
      const multipleServicesForm = this.safetyProcessesForm.controls[this.multipleServicesFormGroupName] as FormGroup;
      const driverInfo = this.createBatchServicesDriverInfo(multipleServicesForm.controls['servicesSelections'] as FormGroup);

      // Employee Batch
      this.createEmployeeBatch(batchBase, driverInfo);

      // Non-Employee Batch
      this.createNonEmployeeBatch(batchBase, driverInfo);

      // Training Batch
      if (this.precheckTrainingOptions()) {
        if (this.trainingAssignmentsForm.valid) {
          this.createTrainingBatch(batchBase, driverInfo);
        } else {
          this.promptUserErrorAlert('Form invalid. Please review selections and submit again.');
          return;
        }
      }

    }

    this.submitBatchService.submitBatch(this.batchRequest).subscribe({
      next: (data) => {
        this.loadingSpinnerService.hide();
        this.isSubmitted = true;
        this.alertService.showSuccessAlert(
          'Your request has been submitted.',
          'end',
          'top',
          5000
        );
        this.routerService.navigate(['/statcenter/', 2]);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }

  private processLegacyRequest(): void {
    this.loadingSpinnerService.show();
    const batchData: Legacy_Batch = { ClientCode: this._safetyClientSelected };
    if (this.safetyProcessesForm.valid) {
      const multipleServicesForm = this.safetyProcessesForm.controls[this.multipleServicesFormGroupName] as UntypedFormGroup;
      const selectedProcessesFg = multipleServicesForm.controls['servicesSelections'] as UntypedFormGroup;
      const selectedDriversForm = this.safetyProcessesForm.controls['selectedDrivers'] as UntypedFormGroup;
      const multipleServicesIncludedDrivers = selectedDriversForm.controls['includedDrivers'].value as Array<number>;
      const multipleServicesExcludedDrivers = selectedDriversForm.controls['excludedDrivers'].value as Array<number>;

      const includedDriversStr = Array.isArray(multipleServicesIncludedDrivers) && multipleServicesIncludedDrivers.length
        ? multipleServicesIncludedDrivers.join(',')
        : '';
      const excludedDriversStr = Array.isArray(multipleServicesExcludedDrivers) && multipleServicesExcludedDrivers.length
        ? multipleServicesExcludedDrivers.join(',')
        : '';

      if (
        selectedProcessesFg.controls[SafetyProcessList.Mvr].value === true ||
        selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation].value === true
      ) {
        batchData.MvrDriverIdList = includedDriversStr;
        batchData.MvrDriverIdExcludeList = excludedDriversStr;
        batchData.MvrDataValidationOnly =
          (selectedProcessesFg.controls[SafetyProcessList.Mvr].value === true) ? false : true;

        if (selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation].value === true) {
          batchData.MvrDataValidationDue =
            new Date(selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation + 'dd'].value)
              .toLocaleDateString('en-US');
        }

        if (selectedProcessesFg.controls[SafetyProcessList.CertificateOfViolation].value === true) {
          batchData.ProcessId = 2;
          batchData.IsCOVRequested = true;
        }
      }

      if (selectedProcessesFg.controls[SafetyProcessList.Monitoring].value === true) {
        batchData.MvrMonitoringDriverIdList = includedDriversStr;
        batchData.MvrMonitoringDriverIdExcludeList = excludedDriversStr;
      }

      if (selectedProcessesFg.controls[SafetyProcessList.AutoCoverage].value === true) {
        batchData.CertificateOfInsuranceDriverIdList = includedDriversStr;
        batchData.CertificateOfInsuranceDriverIdExcludeList = excludedDriversStr;
      }

      if (selectedProcessesFg.controls[SafetyProcessList.PolicySignOff].value === true) {
        batchData.PolicySignoffDriverIdList = includedDriversStr;
        batchData.PolicySignoffDueDate =
          new Date(selectedProcessesFg.controls[SafetyProcessList.PolicySignOff + 'dd'].value)
            .toLocaleDateString('en-US');
      }

      if (selectedProcessesFg.controls[SafetyProcessList.LicenseUpload].value === true) {
        batchData.LicenseUploadDriverIdList = includedDriversStr;
        batchData.LicenseUploadDriverIdExcludeList = excludedDriversStr;
        batchData.LicenseUploadDueDate =
          new Date(selectedProcessesFg.controls[SafetyProcessList.LicenseUpload + 'dd'].value)
            .toLocaleDateString('en-US');
      }

      if (selectedProcessesFg.controls[SafetyProcessList.DriverQualification].value === true) {
        batchData.EnrollInDQService = true;
        batchData.DQDriverIdList = includedDriversStr;
        batchData.IsDQMedicalCardRequested = selectedProcessesFg.controls[SafetyProcessList.DqMedCard].value;
        batchData.IsDQDriversLicenseUploadRequested = selectedProcessesFg.controls[SafetyProcessList.DqLicenseUpload].value;
        batchData.IsDQDriverApplicationRequested = selectedProcessesFg.controls[SafetyProcessList.DqDriverApplication].value;
        batchData.IsDQCOVRequested = selectedProcessesFg.controls[SafetyProcessList.DqCertificateOfViolation].value;
        batchData.IsDQMVRRequested = selectedProcessesFg.controls[SafetyProcessList.Mvr].value;
        batchData.IsDQRecordOfRoadTestRequested = selectedProcessesFg.controls[SafetyProcessList.DqRecordOfRoadTest].value;
        batchData.IsDQCertificateOfRoadTestRequested = selectedProcessesFg.controls[SafetyProcessList.DqCertificateOfRoadTest].value;
        batchData.IsDQDriversLogRequested = selectedProcessesFg.controls[SafetyProcessList.DqDriverLog].value;
        batchData.IsDQClearingHouseLimitedSignoffRequested = selectedProcessesFg.controls[SafetyProcessList.DqClearingHouse].value;
      }

    }

    if (this.trainingAssignmentsForm.valid) {
      const trainingDriversForm = this.trainingAssignmentsForm.controls['selectedDrivers'] as UntypedFormGroup;
      const selectedTrainingLessonsForm = this.trainingAssignmentsForm.controls[this.trainingFormGroupName] as UntypedFormGroup;
      const safetyServicesSelections = selectedTrainingLessonsForm.controls['servicesSelections'] as UntypedFormGroup;
      const trainingLessonsForm = selectedTrainingLessonsForm.controls['trainingSelections'] as UntypedFormGroup;
      const trainingLessonsFormArray = trainingLessonsForm.controls['selectedLessons'] as UntypedFormArray;
      const trainingIncludedDrivers = trainingDriversForm.controls['includedDrivers'].value as Array<number>;
      const trainingExcludedDrivers = trainingDriversForm.controls['excludedDrivers'].value as Array<number>;

      batchData.TrainingDriverIdList = Array.isArray(trainingIncludedDrivers) && trainingIncludedDrivers.length
        ? trainingIncludedDrivers.join(',')
        : '';

      batchData.TrainingDriverIdExcludeList = Array.isArray(trainingExcludedDrivers) && trainingExcludedDrivers.length
        ? trainingExcludedDrivers.join(',')
        : '';

      const selectedLessons = trainingLessonsFormArray.controls
        .filter((cont: UntypedFormGroup) => cont.controls['selected'].value)
        .map((cont: UntypedFormGroup) => {
          return cont.controls['lesson'].value as DriverTrainingCourse;
        });

      batchData.TrainingCourseGroupList = selectedLessons.map(lesson => lesson.courseGroupId).join(',');
      batchData.TrainingPassRateList = selectedLessons.map(lesson => lesson.passRate).join(',');
      batchData.TrainingDueDate = new Date(safetyServicesSelections.controls[SafetyProcessList.Training + 'dd'].value)
        .toLocaleDateString('en-US');

    }

    this.submitBatchService.legacy_SubmitBatch(batchData)
      .subscribe({
        next: (result) => {
          this.loadingSpinnerService.hide();
          this.isSubmitted = true;
          this.alertService.showSuccessAlert('Your request has been submitted.', 'end', 'top', 5000);
          this.routerService.navigate(['/statcenter/', 2]);
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }

}


interface TabHeader {
  criteriaHeaderHtml: string;
  driverSelectionHeaderHtml: string;
}
